import React from 'react';
import { Link } from "react-router-dom";

import './Navbar.css';
import logoWebsite from 'assets/images/logo-website-simplify.png'
import { SideDrawer } from "components/SideDrawer";

export class Navbar extends React.Component {
  sideDrawerRef: any;

  constructor(props: any) {
    super(props);
    
    this.sideDrawerRef = React.createRef<SideDrawer>();
  }

  handleClick = () => {
    this.sideDrawerRef.current.toggle();
  }

  render() {
    return (
      <section className="relative bg-black overflow-hidden">
        <nav className="relative flex px-16 py-8 lg:py-0 justify-between bg-transparent border-b">
          <div className="pr-14 flex items-center">
            <Link to="/" className="inline-block text-xl text-white font-medium font-heading">
              <img className="h-8" width="auto" src={logoWebsite} alt="" />
            </Link>
          </div>
          <button onClick={this.handleClick} className="navbar-burger lg:hidden self-center">
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y={6} width={24} height={2} fill="white" /><rect y={11} width={24} height={2} fill="white" /><rect y={16} width={24} height={2} fill="white" /></svg>
          </button>
          <div className="hidden lg:block pl-16 py-8">
            <ul className="flex justify-center">
              <li className="mr-12"><Link to="/" className="text-gray-500 hover:text-gray-400">About</Link></li>
              <li className="mr-12"><Link to="/company" className="text-gray-500 hover:text-gray-400">Company</Link></li>
              <li className="mr-12"><Link to="/approach" className="text-gray-500 hover:text-gray-400">Approach</Link></li>
              <li className="mr-12"><Link to="/portfolio" className="text-gray-500 hover:text-gray-400">Portfolio</Link></li>
              <li><Link to="/contact" className="text-gray-500 hover:text-gray-400">Contact</Link></li>
            </ul>
          </div>
        </nav>
        <SideDrawer ref={this.sideDrawerRef}>
          <ul className="h-full mt-16">
            <li className="mb-10">
              <Link to="/" onClick={this.handleClick} className="flex items-center">
                <span className="mr-3 text-lg text-white">About</span>
                <svg width={16} height={9} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#FFEC3E" /></svg>
              </Link>
            </li>
            <li className="mb-10">
              <Link to="/company" onClick={this.handleClick} className="flex items-center">
                <span className="mr-3 text-lg text-white">Company</span>
                <svg width={16} height={9} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#FFEC3E" /></svg>
              </Link>
            </li>
            <li className="mb-10">
              <Link to="/approach" onClick={this.handleClick} className="flex items-center">
                <span className="mr-3 text-lg text-white">Approach</span>
                <svg width={16} height={9} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#FFEC3E" /></svg>
              </Link>
            </li>
            <li className="mb-10">
              <Link to="/portfolio" onClick={this.handleClick} className="flex items-center">
                <span className="mr-3 text-lg text-white">Portfolio</span>
                <svg width={16} height={9} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#FFEC3E" /></svg>
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={this.handleClick} className="flex items-center">
                <span className="mr-3 text-lg text-white">Contact</span>
                <svg width={16} height={9} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#FFEC3E" /></svg>
              </Link>
            </li>
          </ul>
          <p className="text-sm text-gray-500">Copyright © Simplify Software Pty Ltd 2023</p>
        </SideDrawer>
      </section>
    );
  }
}
