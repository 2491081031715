import { Article } from 'components/Article';

export function PlanAndManage(props: any) {
  const { ctaText, ctaAction } = props;

  return (
    <Article title="PLAN AND MANAGE" ctaText={ctaText} ctaAction={ctaAction}>
      <p className="mb-8 text-lg text-gray-500">Simplify Software focusses on delivering the software that you want. Crucial to meeting your requirements is careful planning and effective management throughout the software development life cycle.</p>
      <p className="mb-8 text-lg text-gray-500">We work with you to define your requirements and create a scope of works so all parties are clear on your project's objectives. Once the deliverables are finalised, we break down tasks, estimate effort and resources, and create you a realistic project schedule.</p>
      <p className="text-lg text-gray-500">We use Agile tools and methodologies coupled with regular communication and collaboration among team members, stakeholders, and clients to ensure a continued shared understanding and alignment of the deliverables, meaning no last-minute surprises.</p>
    </Article>
  )
};