import { Article } from 'components/Article';

export function SecureDeliverAndSupport(props: any) {
  const { ctaText, ctaAction } = props;

  return (
    <Article title="SECURE, DELIVER AND SUPPORT" ctaText={ctaText} ctaAction={ctaAction}>
      <p className="mb-8 text-lg text-gray-500">To protect your confidentiality, integrity and the availability of your systems, Simplify Software will build security into your application from the ground up. We'll also engage third-parties to perform penetration testing at your request.</p>
      <p className="text-lg text-gray-500">Delivery is just the beginning of a new phase of a software's life so we'll happily continue to support and enhance the solution we built for you. To ensure faster time to market, we'll configure your DevOps platform for continuous integration and continuous delivery of your application.</p>
    </Article>
  )
};