import './Company.css';
import { useNavigate } from 'react-router-dom';
import { Article } from 'components/Article';
import { CtaLink } from 'components/CtaLink';

export function Company() {
  const navigate = useNavigate();

  const handleClick = (event: any, route: string) => {
    event.preventDefault();
    navigate(route)
  }

  return (
    <section className="bg-black overflow-hidden" contentEditable={false}>
      <Article title="TAKE THE HASSLE OUT OF YOUR NEXT SOFTWARE PROJECT." ctaText="See our approach" ctaAction={(e: any) => handleClick(e, "/approach")}>
        <p className="mb-8 text-lg text-gray-500">Simplify Software specialises in progressive, user-focussed web applications that connect seamlessly to your business architecture.</p>
        <p className="mb-8 text-lg text-gray-500">Through 25 years of experience, our knowledge is the key to simplifying the complexity of software projects. Whether it's building out a new web app or integrating with a third-party API, we'll make your development journey smooth.</p>
        <p className="mb-8 text-lg text-gray-500">We're ready to bring your next project to life.&nbsp;&nbsp;<CtaLink text="Get in touch" onClick={(e: any) => handleClick(e, "/contact")} /></p>
      </Article>
    </section>
  );
}
