import './About.css';
import { useNavigate } from 'react-router-dom';
import backgroundCityScape from 'assets/images/background-city-scape.jpg'
import shadowBlueFull from 'assets/images/shadow-blue-full.svg'
import shadowLight7 from 'assets/images/shadow-light-7.svg'
import { CtaButton } from 'components/CtaButton';

export function About() {
  const navigate = useNavigate();

  const handleClick = (event: any, route: string) => {
    event.preventDefault();
    navigate(route)
  }

  return (
    <section className="bg-black">
      <div className="container mx-auto px-4">
        <div className="relative pt-20 pb-10 overflow-hidden">
          <div className="absolute top-0 left-1/2 mt-20 transform -translate-x-1/2 -ml-20 rounded-full h-20 md:h-128 w-80 md:w-128">
            <img className="object-contain transform scale-200" src={shadowBlueFull} alt="" />
          </div>
          <div className="absolute top-0 left-1/2 mt-40 lg:mt-20 rounded-full h-20 md:h-128 w-80 md:w-128">
            <img className="object-contain transform scale-200" src={shadowLight7} alt="" />
          </div>
          <div className="absolute top-0 right-1/2 mt-20 sm:mt-0 w-full max-w-3xl transform translate-x-1/2 rotate-45 clip-path">
            <img className="transform -rotate-45 opacity-70" src={backgroundCityScape} alt="" />
          </div>
          <div className="relative max-w-6xl mx-auto lg:mt-20">
            <span className="mb-4 top-0 right-0 text-gray-500 font-semibold uppercase tracking-widest block">COMPLEXITY MADE SIMPLE</span>
            <h2 className="mb-6 md:mb-0 text-white uppercase font-heading text-2xl md:text-4xl">
              <span>FROM CONCEPTION</span>
              <span className="block">TO DELIVERY</span>
              <span className="block text-right">AND BEYOND...</span>
            </h2>
          </div>
          <div className="flex justify-end">
            <div className="w-full md:w-2/6">
              <div className="flex md:h-80 items-center justify-center px-4 bg-black">
                <CtaButton text="Find out more" onClick={(e: any) => handleClick(e, "/company")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
