import React from 'react';
import { Link } from 'react-router-dom';
import './SideDrawer.css';
import logoWebsite from 'assets/images/logo-website-simplify-silver.png'

type SideDrawerProps = {
  behavior?: string,
  compactView?: boolean,
  children: React.ReactNode
};

type SideDrawerState = {
  children: React.ReactNode,
  newChildren: React.ReactNode,
  transitionClassName: string
}

export const SideDrawerBehavior = {
  leftSlide: "left-slide",
  rightSlide: "right-slide"
};

export class SideDrawer extends React.Component<SideDrawerProps, SideDrawerState> {
  behavior: string;
  contentPadding: string;
  headerPadding: string;
  sideDrawerRef: any;
  sideDrawerNavRef: any;

  constructor(props: SideDrawerProps) {
    super(props);
    
    let compactView = props.compactView == null ? false : props.compactView;

    this.behavior = props.behavior ?? SideDrawerBehavior.leftSlide;
    this.headerPadding = compactView ? "pt-4 px-5 md:pt-8 md:px-10" : "pt-8 px-10";
    this.contentPadding = compactView ? "pb-4 px-5 md:pb-8 md:px-10" : "pb-8 px-10";
    this.state = {
      children: props.children,
      newChildren: null,
      transitionClassName: ""
    };    
    this.sideDrawerRef = React.createRef<HTMLDivElement>();
    this.sideDrawerNavRef = React.createRef<HTMLElement>();
  }

  componentDidMount() {
    this.sideDrawerRef.current.classList.add(this.behavior);

    this.sideDrawerNavRef.current.addEventListener("animationend", () => {
      if (this.sideDrawerRef.current.classList.contains(this.behavior + '-hide')) {
        this.sideDrawerRef.current.classList.add('hidden');
      }
    });
  }

  setChildren(newChildren: any, animate: boolean) {
    if (animate) {
      this.setState({ newChildren: newChildren, transitionClassName: "side-drawer-children-exit" });
    }
    else {
      this.setState({ children: newChildren, transitionClassName: "" });
    }
  }

  toggle() {
    if (this.sideDrawerRef.current.classList.contains(this.behavior + '-show')) {
      this.sideDrawerRef.current.classList.remove(this.behavior + '-show');
      this.sideDrawerRef.current.classList.add(this.behavior + '-hide');
    }
    else {
      this.sideDrawerRef.current.classList.remove('hidden');
      this.sideDrawerRef.current.classList.remove(this.behavior + '-hide');
      this.sideDrawerRef.current.classList.add(this.behavior + '-show');
    }
  }

  handleClick = () => {
    this.toggle();
  }

  renderClose(): any {
    if (this.behavior === SideDrawerBehavior.rightSlide) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
        </svg>
      );
    }

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" viewBox="0 0 16 16">
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    );
  }

  render(): any {
    return (
      <div ref={this.sideDrawerRef} className={`hidden side-drawer fixed top-0 left-0 bottom-0 w-full md:w-5/6 z-50`}>
        <div onClick={this.handleClick} className="side-drawer-backdrop fixed inset-0 bg-gray-800 opacity-25" />
        <nav ref={this.sideDrawerNavRef} className={`relative flex flex-col w-full h-full bg-black`}>
          <div className={`relative flex items-center justify-between bg-transparent ${this.headerPadding} ${this.behavior === SideDrawerBehavior.rightSlide ? "flex-row-reverse" : ""}`}>
            <Link to="/" onClick={this.handleClick} className="inline-block text-xl text-white font-medium font-heading">
              <img src={logoWebsite} alt="" width={100} />
            </Link>
            <button type='button' onClick={this.handleClick}>
              {this.renderClose()}
            </button>
          </div>
          <div className={`${this.contentPadding} bg-black ${this.state.transitionClassName}`} onAnimationEnd={() => {
            if (this.state.transitionClassName === "side-drawer-children-exit") {
              window.scrollTo(0, 0);
              this.setState({ children: this.state.newChildren, newChildren: null, transitionClassName: "side-drawer-children-enter" });
            }
          }}>
            {this.state.children}
          </div>
        </nav>
      </div>
    );
  }
}
