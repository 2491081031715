import './CtaButton.css';
import ellipse from 'assets/images/ellipse.svg'

export function CtaButton(props: any) {
  const { text, onClick } = props;

  return (
    <a className="relative inline-flex items-center h-52 md:h-64 text-white cta-button" href="#" onClick={onClick}>
      <img className="absolute top-0 h-full left-0" src={ellipse} alt="" />
      <div className="flex w-full justify-center items-center">
        <span className="cta-button-text mr-2 text-sm font-heading text-center">{text}</span>
        <svg width={17} height={9} viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3264 3.70975H0.316406V5.80206H12.3264V8.94052L16.3164 4.7559L12.3264 0.571289V3.70975Z" fill="#FFEC3E" /></svg>
      </div>
    </a>
  );
}
