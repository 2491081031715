import './Portfolio.css';
import portfolioMgmPortal from 'assets/images/portfolio-mgm-portal.jpg'
import portfolioMgmWebsite from 'assets/images/portfolio-mgm-website.jpg'
import portfolioMgmWaterfront from 'assets/images/portfolio-mgm-waterfront.jpg'
import portfolioRhcGlobalWebsite from 'assets/images/portfolio-rhc-global-website.jpg'
import portfolioRhcMentalHealthWebsite from 'assets/images/portfolio-rhc-mh-website.jpg'
import portfolioRhcPharmacyWebsite from 'assets/images/portfolio-rhc-pharmacy-website.jpg'

export function Portfolio() {
  return (
    <section className="bg-black overflow-hidden pt-10">
      <div className="relative container mx-auto px-4">
        <div className="relative flex flex-wrap -mx-4 mb-16 lg:mb-12">
          <div className="w-full lg:w-1/3 px-4 mb-10 lg:mb-0">
            <a href="https://portal.thegroovemerchants.com" target="_blank" className="portfolio-hover">
              <div className="transform rotate-45 clip-path-2 w-96 h-96">
                <img className="transform -rotate-45 h-full w-full" src={portfolioMgmPortal} alt="" /></div>
              <div className="lg:max-w-xs">
                <h3 className="mb-6 text-2xl text-white font-heading">MGM Distribution Client Portal</h3>
                <p className="text-lg text-gray-500">A secure online platform enabling MGM's clients to upload their digital content and view their streaming platform analytics.</p>
              </div>
            </a>
          </div>
          <div className="w-full lg:w-1/3 px-4 mb-10 lg:mb-0">
            <a href="https://www.thegroovemerchants.com" target="_blank" className="portfolio-hover">
              <div className="transform rotate-45 clip-path-2 w-96 h-96">
                <img className="transform -rotate-45 h-full w-full" src={portfolioMgmWebsite} alt="" /></div>
              <div className="lg:max-w-xs">
                <h3 className="mb-6 text-2xl text-white font-heading">MGM Distribution Website</h3>
                <p className="text-lg text-gray-500">MGM's showpiece for potential clients and partners to gather insights into the company's products and services.</p>
              </div>
            </a>
          </div>
          <div className="w-full lg:w-1/3 px-4">
            <a href="https://twelvefootninja.mywaterfrontstore.com/" target="_blank" className="portfolio-hover">
              <div className="transform rotate-45 clip-path-2 w-96 h-96">
                <img className="transform -rotate-45 h-full w-full" src={portfolioMgmWaterfront} alt="" /></div>
              <div className="lg:max-w-xs">
                <h3 className="mb-6 text-2xl text-white font-heading">My Waterfront Store Platform</h3>
                <p className="text-lg text-gray-500">A brandable e-commerce platform for MGM's clients to sell their music and merchandise direct to the consumer.</p>
              </div>
            </a>
          </div>
          <div className="w-full lg:w-1/3 px-4">
            <a href="https://www.ramsayhealth.com" target="_blank" className="portfolio-hover">
              <div className="transform rotate-45 clip-path-2 w-96 h-96">
                <img className="transform -rotate-45 h-full w-full" src={portfolioRhcGlobalWebsite} alt="" /></div>
              <div className="lg:max-w-xs">
                <h3 className="mb-6 text-2xl text-white font-heading">Ramsay Health Care Global Website</h3>
                <p className="text-lg text-gray-500">The gateway to Ramsay's network of hospitals, clinics and services in Australia, Asia, Europe and the United Kingdom.</p>
              </div>
            </a>
          </div>
          <div className="w-full lg:w-1/3 px-4">
            <a href="https://www.ramsaymentalhealth.com.au" target="_blank" className="portfolio-hover">
              <div className="transform rotate-45 clip-path-2 w-96 h-96">
                <img className="transform -rotate-45 h-full w-full" src={portfolioRhcMentalHealthWebsite} alt="" /></div>
              <div className="lg:max-w-xs">
                <h3 className="mb-6 text-2xl text-white font-heading">Ramsay Mental Health Website</h3>
                <p className="text-lg text-gray-500">The customer-focussed virtual front for Ramsay Mental Health, the largest private provider of mental health care in Australia.</p>
              </div>
            </a>
          </div>
          <div className="w-full lg:w-1/3 px-4">
            <a href="https://www.ramsaypharmacyonline.com.au" target="_blank" className="portfolio-hover">
              <div className="transform rotate-45 clip-path-2 w-96 h-96">
                <img className="transform -rotate-45 h-full w-full" src={portfolioRhcPharmacyWebsite} alt="" /></div>
              <div className="lg:max-w-xs">
                <h3 className="mb-6 text-2xl text-white font-heading">Ramsay Pharmacy Online Website</h3>
                <p className="text-lg text-gray-500">Ramsay Pharmacy Online provides customers access to medications and healthcare products from the comfort of their own homes.</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
