import { Article } from 'components/Article';

export function ConnectYourBusiness(props: any) {
  const { ctaText, ctaAction } = props;

  return (
    <Article title="CONNECT YOUR BUSINESS" ctaText={ctaText} ctaAction={ctaAction}>
      <p className="mb-8 text-lg text-gray-500">Connecting your business systems is essential for achieving seamless data flow and efficient operations within your organization. Use Simplify Software's expertise in system integration to connect your new solution with your existing business systems.</p>
      <p className="text-lg text-gray-500"></p>
    </Article>
  )
};