import React from 'react';
import { CtaButton } from 'components/CtaButton';
import './Article.css';
import logoSimpleWebsite from 'assets/images/logo-simple-website-simplify.png'

export function Article(props: any) {
  const { title, children, ctaText, ctaAction } = props;

  return (
    <div className="container mx-auto px-4 mb-12 md:mb-0">
      <div className="flex flex-wrap items-center">
        <div className="w-full md:w-4/6 mb-8 md:mb-0 border-b md:border-b-0 md:border-r">
          <div className="py-12">
            <div className="max-w-xl mx-auto">
              <h2 className="mb-6 text-2xl md:text-3xl text-white uppercase font-heading bg-black">{title}</h2>
              <div className="flex items-center mb-10">
                <img className="h-24" src={logoSimpleWebsite} alt="" /><div className="ml-4">
                  <h3 className="mb-2 text-white font-heading">Simplify Software</h3>
                  <p className="text-xs text-gray-500 uppercase font-semibold tracking-widest">COMPLEXITY MADE SIMPLE</p>
                </div>
              </div>
              <div>
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/6">
          <div className="flex md:h-80 items-center justify-center px-4 bg-black">
            <CtaButton text={ctaText} onClick={ctaAction} />
          </div>
        </div>
      </div>
    </div>
  );
}
