import './CtaLink.css';

export function CtaLink(props: any) {
  const { text, onClick } = props;

  return (
    <a className="inline-flex items-center text-white cta-link" href="#" onClick={onClick}>
      <span className="mr-2">{text}</span>
      <svg width={16} height={10} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.01 3.95383H0V6.04614H12.01V9.1846L16 4.99998L12.01 0.815369V3.95383Z" fill="#FFEC3E" /></svg>
    </a>
  );
}
