import './Footer.css';
import logoWebsite from 'assets/images/logo-website-simplify-silver.png'
// import logoFacebook from 'assets/images/facebook.svg'
// import logoTwitter from 'assets/images/twitter.svg'
// import logoInstagram from 'assets/images/instagram.svg'
// import logoGithub from 'assets/images/github.svg'
// import logoLinkedin from 'assets/images/linkedin.svg'

export function Footer() {
  return (
    <section className="bg-black overflow-x-hidden">
    <div className="py-10 border-t">
      <div className="container mx-auto px-4">
        <div className="flex justify-between flex-wrap sm:flex-nowrap">
          <div className="w-full mb-4 sm:mb-0">
            <a className="inline-block text-xl text-white font-medium font-heading" href="#">
              <img src={logoWebsite} alt="" width={100} />
            </a>
          </div>
          <p className="text-gray-500 whitespace-nowrap">Copyright © Simplify Software Pty Ltd {new Date().getFullYear()}</p>
          {/* <div className="w-full md:w-auto">
            <div className="flex flex-wrap">
              <a className="mr-8" href="#">
                <img src={logoFacebook} alt="" />
              </a>
              <a className="mr-8" href="#">
                <img src={logoTwitter} alt="" />
              </a>
              <a className="mr-8" href="#">
                <img src={logoInstagram} alt="" />
              </a>
              <a className="mr-8" href="#">
                <img src={logoGithub} alt="" />
              </a>
              <a href="#">
                <img src={logoLinkedin} alt="" />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </section>
  );
}
