import { useEffect, useState } from 'react';
import { Routes, Route,  useLocation } from 'react-router-dom';

import './App.css';
import { About } from 'pages/About';
import { Company } from 'pages/Company';
import { Approach } from 'pages/Approach';
import { Portfolio } from 'pages/Portfolio';
import { Contact } from 'pages/Contact';
import { Error404 } from 'pages/Error404';
import { Footer, Navbar } from 'components';

function App() {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);
  const [transitionClassName, setTransitionClassName] = useState("router-transition-enter");

  useEffect(() => {
    if (location !== currentLocation) {
      setTransitionClassName("router-transition-exit");
    }
  }, [location, currentLocation]);

  const animationEnd = () => {
    if (transitionClassName === "router-transition-exit") {
      window.scrollTo(0, 0);
      setTransitionClassName("router-transition-enter");
      setCurrentLocation(location);
    }
  }

  return (
    <div className="app">
      <Navbar />
      <div className={`${transitionClassName}`} onAnimationEnd={animationEnd}>
        <Routes location={currentLocation}>
          <Route index element={<About />} />
          <Route path="company" element={<Company />} />
          <Route path="approach" element={<Approach />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Error404 />} />    
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
