import './Error404.css';

export function Error404() {
  return (
    <section className="h-screen bg-black bg-no-repeat bg-cover">
      <div className="flex h-full items-center">
        <div className="container mx-auto px-4">
          <div className="max-w-md mx-auto text-center">
            <h2 className="mb-4 text-2xl text-white font-heading">Page not found</h2>
            <p className="mb-10 text-lg text-gray-500">Sorry! We are unable to find the page you are looking for</p>
            <div className="flex flex-wrap items-center justify-center">
              <a className="inline-flex items-center text-white hover:underline" href="/">
                <span className="mr-2 text-sm font-heading">Back to home</span>
                <svg width={16} height={10} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.01 3.95383H0V6.04614H12.01V9.1846L16 4.99998L12.01 0.815369V3.95383Z" fill="#FFEC3E" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
