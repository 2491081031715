import { Article } from 'components/Article';

export function DesignBuildAndTest(props: any) {
  const { ctaText, ctaAction } = props;

  return (
    <Article title="DESIGN, BUILD AND TEST" ctaText={ctaText} ctaAction={ctaAction}>
      <p className="mb-8 text-lg text-gray-500">Well-designed software plays a pivotal role in the success of any digital solution. At Simplify Software, we believe in building software for the future by creating better user experience, functionality, maintainability, scalability, and performance. The benefits for your business include improved user satisfaction, reduced development and maintenance costs, and a competitive edge in the market.</p>
      <p className="text-lg text-gray-500">We test continually throughout the software development life cycle to ensure quality, reliability, and functionality of your solution. Using a test-driven development approach coupled with quality assurance and performance testing, we'll ensure your software is thoroughly tested before proceeding to the user-acceptance testing phase.</p>
    </Article>
  )
};