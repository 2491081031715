import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Approach.css';
import ellipse from 'assets/images/ellipse.svg'
import { CtaLink } from 'components/CtaLink';
import { SideDrawer, SideDrawerBehavior } from 'components/SideDrawer';
import { ConnectYourBusiness, DesignBuildAndTest, PlanAndManage, SecureDeliverAndSupport } from 'components/Articles';

export function Approach() {
  const navigate = useNavigate();
  const sideDrawerRef = React.useRef<SideDrawer>(null);
  const articles = [
    { index: 1, article: PlanAndManage({ ctaText: "Design, build and test", ctaAction: (e: any) => handleCtaAction(e, 2) }) },
    { index: 2, article: DesignBuildAndTest({ ctaText: "Connect your business", ctaAction: (e: any) => handleCtaAction(e, 3) }) },
    { index: 3, article: ConnectYourBusiness({ ctaText: "Secure, deliver and support", ctaAction: (e: any) => handleCtaAction(e, 4) }) },
    { index: 4, article: SecureDeliverAndSupport({ ctaText: "Check our work", ctaAction: () => navigate("/portfolio") }) }
  ];

  const handleClick = (event: any, index: number) => {
    event.preventDefault();
    sideDrawerRef.current?.setChildren(articles.find(a => a.index === index)?.article, false);
    sideDrawerRef.current?.toggle();
  }

  const handleCtaAction = (event: any, index: number) => {
    event.preventDefault();
    sideDrawerRef.current?.setChildren(articles.find(a => a.index === index)?.article, true);
  }

  return (
    <section className="relative bg-black overflow-hidden pt-20">
      <div className="relative container mx-auto px-4">
        <div className="max-w-xl mx-auto mb-8 md:mb-14 text-center">
          <h2 className="mb-6 text-3xl text-white uppercase font-heading" contentEditable={false}>OUR APPROACH</h2>
          <p className="text-lg text-gray-500" contentEditable={false}>How you approach a software project can mean the difference between success and failure.&nbsp;</p>
        </div>
        <div className="flex flex-wrap -mx-4 mb-20">
          <div className="w-full md:w-1/2 px-4 mb-10">
            <div className="flex flex-wrap items-center">
              <div className="approach-ellipse relative flex items-center justify-center h-40 w-1/3">
                <img className="absolute top-0 left-0 w-full h-full" src={ellipse} alt="" /><span className="text-yellow-300">1</span>
              </div>
              <div className="w-full md:w-2/3">
                <h3 className="mb-4 text-2xl text-white font-heading" contentEditable={false}>Plan and manage</h3>
                <p className="text-gray-500" contentEditable={false}>
                  Good planning and management is key to delivering a successful software project.
                </p>
                <div className="pt-2"><CtaLink text="Read more" onClick={(e: any) => handleClick(e, 1)} /></div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-10">
            <div className="flex flex-wrap items-center">
              <div className="approach-ellipse relative flex items-center justify-center h-40 w-1/3">
                <img className="absolute top-0 left-0 w-full h-full" src={ellipse} alt="" /><span className="text-yellow-300">2</span>
              </div>
              <div className="w-full md:w-2/3">
                <h3 className="mb-4 text-2xl text-white font-heading" contentEditable={false}>Design, build and test</h3>
                <p className="text-gray-500" contentEditable={false}>
                  Well-designed software yields long-term benefits for your business.
                </p>
                <div className="pt-2"><CtaLink text="Read more" onClick={(e: any) => handleClick(e, 2)} /></div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-10">
            <div className="flex flex-wrap items-center">
              <div className="approach-ellipse relative flex items-center justify-center h-40 w-1/3">
                <img className="absolute top-0 left-0 w-full h-full" src={ellipse} alt="" /><span className="text-yellow-300">3</span>
              </div>
              <div className="w-full md:w-2/3">
                <h3 className="mb-4 text-2xl text-white font-heading" contentEditable={false}>Connect your business</h3>
                <p className="text-gray-500" contentEditable={false}>
                  Connecting business systems is essential in a world of distributed technology.
                </p>
                <div className="pt-2"><CtaLink text="Read more" onClick={(e: any) => handleClick(e, 3)} /></div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <div className="flex flex-wrap items-center">
              <div className="approach-ellipse relative flex items-center justify-center h-40 w-1/3">
                <img className="absolute top-0 left-0 w-full h-full" src={ellipse} alt="" /><span className="text-yellow-300">4</span>
              </div>
              <div className="w-full md:w-2/3">
                <h3 className="mb-4 text-2xl text-white font-heading" contentEditable={false}>Secure, deliver and support</h3>
                <p className="text-gray-500" contentEditable={false}>
                  Delivery is never the end of the road for a software project.
                </p>
                <div className="pt-2"><CtaLink text="Read more" onClick={(e: any) => handleClick(e, 4)} /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SideDrawer behavior={SideDrawerBehavior.rightSlide} compactView={true} ref={sideDrawerRef}>
      </SideDrawer>
    </section>
  );
}
